<template>
  <article class="message is-warning is-small" v-if="error_message">
    <div class="message-body">
      {{ error_message }}
    </div>
  </article>
  <div class="content" v-if="is_allow_action">
    <div class="deliverybox-info">
      <b>
        {{ deliverybox.store_address }}
      </b>
      <br />
      <b>
        {{ deliverybox.store_name }}&nbsp;&nbsp;{{
          deliverybox.store_locker_name
        }}</b
      >
    </div>
    商品をボックスに入れ、以下の暗証番号をセットしてドアをロックしてください。
    <div class="box-key">
      {{ deliverybox.store_locker_key }}
    </div>
    <ActionFlow
      select_status="done"
      post_status="done"
      complete_status="active"
    />
    <div class="btn-wrapper">
      <router-link
        class="button is-rounded is-medium btn-submit"
        :to="{ path: '/store/key-set-confirm', query: $route.query }"
        >次へ</router-link
      >
    </div>
    <p>
      ボックスに入らない商品は発送できません。商品が入るサイズのボックスへ再投函するか、ブラウザを閉じてください。
    </p>
  </div>
</template>

<script>
import ActionFlow from "../components/elements/ActionFlow.vue"

export default {
  name: "StoreKeySet",
  components: {
    ActionFlow,
  },
  data() {
    return {
      is_allow_action: false,
      error_message: "",
    }
  },
  created() {
    if (!this.$store.state.deliverybox.store_locker_key) {
      this.error_message =
        "必要な情報が存在しないため操作ができません。最初から操作を行ってください"
      return
    }
    this.is_allow_action = true
  },
  computed: {
    deliverybox() {
      return this.$store.state.deliverybox
    },
  },
}
</script>

<style scoped>
.deliverybox-info {
  text-align: center;
  padding-bottom: 5px;
}
.box-key {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
}
.btn-wrapper {
  text-align: center;
  padding: 30px 0 50px;
}
</style>
